import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Index from '../views/index.vue'
import Login from '../views/login.vue'
import {
	Message
} from "element-ui"

Vue.use(VueRouter)
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch((err) => err)
}

const routes = [{
		path: '/',
		name: 'view-index',
		component: Index,
		children: [{
				path: '/',
				name: 'view-dashboard',
				component: () => import( /* webpackChunkName: 'view-dashboard' */
					'../views/dashboard/index.vue')
			},
			{
				path: '/menu',
				name: 'view-menu',
				component: () => import( /* webpackChunkName: 'view-menu' */ '../views/menu/index.vue')
			},
			{
				path: '/admin',
				name: 'view-admin',
				component: () => import( /* webpackChunkName: 'view-admin' */ '../views/admin/index.vue')
			},
			{
				path: '/role',
				name: 'view-role',
				component: () => import( /* webpackChunkName: 'view-role' */ '../views/role/index.vue')
			},
			{
				path: '/member',
				name: 'view-member',
				component: () => import( /* webpackChunkName: 'view-member' */ '../views/member/index.vue')
			},
			{
				path: '/admin-log',
				name: 'view-admin-log',
				component: () => import( /* webpackChunkName: 'view-admin-log' */
					'../views/admin-log/index.vue')
			},
			{
				path: '/open-door-records',
				name: 'view-open-door-records',
				component: () => import( /* webpackChunkName: 'view-open-door-records' */
					'../views/open-door-records/index.vue')
			},
			{
				path: '/shop',
				name: 'view-shop',
				component: () => import( /* webpackChunkName: 'view-shop' */ '../views/shop/index.vue')
			},
			{
				path: '/article-type',
				name: 'view-article-type',
				component: () => import( /* webpackChunkName: 'view-article-type' */
					'../views/article-type/index.vue')
			},
			{
				path: '/article',
				name: 'view-article',
				component: () => import( /* webpackChunkName: 'view-article' */ '../views/article/index.vue')
			},
			{
				path: '/system-config',
				name: 'view-system-config',
				component: () => import( /* webpackChunkName: 'view-system-config' */
					'../views/system-config/index.vue')
			},
			{
				path: '/file',
				name: 'view-file',
				component: () => import( /* webpackChunkName: 'view-file' */ '../views/file/index.vue')
			},
			{
				path: '/archive',
				name: 'view-archive',
				component: () => import( /* webpackChunkName: 'view-archive' */ '../views/archive/index.vue')
			},
			{
				path: '/material',
				name: 'view-material',
				component: () => import( /* webpackChunkName: 'view-material' */ '../views/material/index.vue')
			},
			{
				path: '/reservation',
				name: 'view-reservation',
				component: () => import( /* webpackChunkName: 'view-reservation' */
					'../views/reservation/index.vue')
			},
			{
				path: '/rfm',
				name: 'view-rfm',
				component: () => import( /* webpackChunkName: 'view-rfm' */ '../views/rfm/index.vue')
			},
			{
				path: '/rfm-setup',
				name: 'view-rfm-setup',
				component: () => import( /* webpackChunkName: 'view-rfm-setup' */
					'../views/rfm-setup/index.vue')
			},
			{
				path: '/rfm-detail',
				name: 'view-rfm-detail',
				component: () => import( /* webpackChunkName: 'view-rfm-detail' */
					'../views/rfm-detail/index.vue')
			},
			{
				path: '/issue-coupons',
				name: 'view-issue-coupons',
				component: () => import( /* webpackChunkName: 'view-issue-coupons' */
					'../views/issue-coupons/index.vue')
			},
			{
				path: '/coupon',
				name: 'view-coupon',
				component: () => import( /* webpackChunkName: 'view-coupon' */ '../views/coupon/index.vue')
			},
			{
				path: '/goods',
				name: 'view-goods',
				component: () => import( /* webpackChunkName: 'view-goods' */ '../views/goods/index.vue')
			}, {

				path: '/business-goods',
				name: 'business-goods',
				component: () => import( /* webpackChunkName: 'view-goods' */
					'../views/business-goods/index.vue')
			},
			{
				path: '/business-goods/add',
				name: 'view-goods-add',
				component: () => import( /* webpackChunkName: 'view-goods-add' */
					'../views/business-goods/add.vue')
			}, {
				path: '/business-goods/edit',
				name: 'view-business-goods-edit',
				component: () => import( /* webpackChunkName: 'view-goods-add' */
					'../views/business-goods/edit.vue')
			},
			{
				path: '/bi',
				name: 'view-bi',
				component: () => import( /* webpackChunkName: 'view-bi' */ '../views/bi/index.vue')
			},
			{
				path: '/goods/add',
				name: 'view-goods-add',
				component: () => import( /* webpackChunkName: 'view-goods-add' */ '../views/goods/add.vue')
			}, {
				path: '/goods/edit',
				name: 'view-goods-edit',
				component: () => import( /* webpackChunkName: 'view-goods-add' */ '../views/goods/edit.vue')
			},
			{
				path: '/goods-type',
				name: 'view-goods-type',
				component: () => import( /* webpackChunkName: 'view-goods-type' */
					'../views/goods-type/index.vue')
			},
			{
				path: '/order',
				name: 'view-order',
				component: () => import( /* webpackChunkName: 'view-order' */ '../views/order/index.vue')
			}, {
				path: '/banner',
				name: 'article-banner',
				component: () => import('../views/banner/index.vue')
			}, {
				path: '/banner-cate',
				name: 'banner-cate',
				component: () => import('../views/banner-cate/index.vue')
			}, {
				path: '/assignment',
				name: 'article-assignment',
				component: () => import('../views/assignment/list/index.vue')
			}, {
				path: '/freeca',
				name: 'view-freeca',
				component: () => import('../views/freeca/index.vue')
			}, {
				path: '/parkingcar',
				name: 'view-freeca',
				component: () => import('../views/parkingcar/index.vue')
			}, {
				path: '/assignment-cate',
				name: 'article-assignmentcate',
				component: () => import('../views/assignment/cate/index.vue')
			},
			{
				path: '/merchant',
				name: 'article-merchant',
				component: () => import('../views/merchant/list/index.vue')
			},
			{
				path: '/merchant/cate',
				name: 'article-merchant-cate',
				component: () => import('../views/merchant/cate/index.vue')
			},
			{
				path: '/pick',
				name: 'article-pick',
				component: () => import('../views/selfpick/index.vue')
			}, {
				path: '/logistics',
				name: 'article-logistics',
				component: () => import('../views/logistics/index.vue')
			}, {
				path: '/aftersales',
				name: 'article-aftersales',
				component: () => import('../views/aftersales/index.vue')
			}, {
				path: '/agent',
				name: 'article-agent',
				component: () => import('../views/agent/index.vue')
			}, {
				path: '/evaluate',
				name: 'article-evaluate',
				component: () => import('../views/evaluate/index.vue')
			}, {
				path: '/settlement',
				name: 'article-settlement',
				component: () => import('../views/settlement/index.vue')
			}, {
				path: '/business-type',
				name: 'view-business-type',
				component: () => import('../views/business-type/index.vue')
			}, {
				path: '/waitsettled',
				name: 'view-waitsettled',
				component: () => import('../views/waitsettled/index.vue')
			}, {
				path: '/information',
				name: 'view-information',
				component: () => import('../views/information/index.vue')
			}, { //商家待结算
				path: '/businesswait',
				name: 'article-businesswait',
				component: () => import('../views/waitsettlebusiness/index.vue')
			}, { //商家待支付结算列表
				path: '/waitpaybus',
				name: 'article-waitpaybus',
				component: () => import('../views/waitpaysettle/index.vue')
			}, { //预充值列表
				path: '/prerecharge',
				name: 'article-prerecharge',
				component: () => import('../views/prerecharge/index.vue')
			}, { //用户分组
				path: '/groupname',
				name: 'article-groupname',
				component: () => import('../views/group_name/index.vue')
			}, { //车道
				path: '/lane',
				name: 'article-lane',
				component: () => import('../views/lane/index.vue')
			}, { //微信充值
				path: '/wxcard',
				name: 'article-wxcard',
				component: () => import('../views/wxcard/index.vue')
			}, { //代金券
				path: '/voucher',
				name: 'article-voucher',
				component: () => import('../views/voucher/index.vue')
			},
			{
				path: '/forms',
				name: 'article-forms',
				component: () => import('../views/forms/index.vue')
			},
			{
				path: '/merchindex',
				name: 'merchindex',
				component: () => import('../views/merchindex/index.vue')
			},
			{
				path: '/platformindex',
				name: 'platformindex',
				component: () => import('../views/platformindex/index.vue')
			},
			{
				path: '/activity',
				name: 'activity',
				component: () => import('../views/activity/index.vue')
			},{
				path: '/activitysign',
				name: 'activitysign',
				component: () => import('../views/activitysign/index.vue')
			},{
				path: '/gifts',
				name: 'gifts',
				component: () => import('../views/gifts/index.vue')
			},{
				path: '/awordslist',
				name: 'awordslist',
				component: () => import('../views/awordslist/index.vue')
			},
		]
	},
	{
		path: '/login',
		name: 'view-login',
		component: Login
	},
	{
		path: '*',
		name: 'view-404',
		component: () => import( /* webpackChunkName: 'view-404' */ '../views/404.vue')
	}
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
	if (to.path === "/rfm-detail") {
		if (store.state.buttons.findIndex(item => item.path === "rfm-detail") >= 0) {
			next();
		} else {
			Message.error("您没有该页面访问权限");
		}
	} else if (to.path === "/rfm-setup") {
		if (store.state.buttons.findIndex(item => item.path === "rfm-setup") >= 0) {
			next();
		} else {
			Message.error("您没有该页面访问权限");
		}
	} else {
		if (to.path !== '/login' && !store.state.token) {
			router.replace("/login");
		} else {
			next();
		}
	}
})

export default router